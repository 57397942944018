
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import back from '../assets/bgImg.png'
import { Grid, Typography } from '@material-ui/core';
import Aos from 'aos';


const useStyles = makeStyles(theme => ({
    main: {
        background: `linear-gradient(90deg, #161616 45.81%, rgba(0, 0, 0, 0.15) 58.97%), url(${back})`,
        [theme.breakpoints.down('xs')]: {
            background: `linear-gradient(90deg, #161616 15.81%, rgba(0, 0, 0, 0.15) 58.97%), url(${back})`,
        }
        // padding:"5rem 3rem"
        //backgroundAttachment:"fixed",
        //scrollBehavior:"smooth"
    },
    Ourthought: {
        fontFamily: "Aeonik",
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "32.81px",
        color: "#ff5043",

    },
    text: {
        position: "relative",
        top: "40%",
        fontFamily: "Aeonik",

        fontWeight: 700,
        fontSize: "54px",
        lineHeight: "63.28px",
        color: "#f5f5f5",
        marginTop: "1rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: "40px",
            lineHeight: "55.28px",

        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "60px"
        }
    },
    gridContainer: {
        //backgroundColor:"#161616",
        height: "90vh",
        //padding:"10rem 3rem",
        paddingTop: "8rem",
        paddingBottom: "10rem",
        paddingLeft: "6rem",
        //position:"relative",
        //top:"40%",
        [theme.breakpoints.down('xs')]: {
            padding: "5rem 2rem",
            height: "auto"
        }
    },
    container: {
        display: "inline-block",
        position: "relative",
        top: "40%"
    }
}))

function OurThoughts() {
    const classes = useStyles();
    useEffect(() => {
        Aos.init({
            duration: 2000
        });
    }, []);

    return (
        <div className={classes.main}>
            <Grid container >
                <Grid item xs={12} sm={7} md={8} className={classes.gridContainer}>
                    <div data-aos="fade-up-right">
                        <div className={classes.container}>
                            <Typography className={classes.Ourthought}>Our Thoughts</Typography>
                            <Typography className={classes.text}>We are market explorers, because the best way to optimize your campaigns is<span style={{ color: "#ff5043" }}> understanding audiences.</span></Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

export default OurThoughts
