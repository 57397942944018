import React, { useEffect } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
//import { Scrollbars } from 'react-custom-scrollbars';
//import { Grid, Row, Col } from "react-flexbox-grid";
//import './App.css'
import pattern2 from '../assets/pattern2.png';
import FooterImg from '../assets/Footer-logo.png'
import Aos from 'aos';
import FooterImgMob from '../assets/mobile-footer.png'

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                width: "350px",

            }
        }
    }
})

const useStyles = makeStyles((theme) => ({
    headitem: {
        fontFamily: 'Aeonik',
        fontWeight: 700,
        fontSize: '27px',
        color: '#ffffff',
        marginTop: '30px',
        marginLeft: '40px'
    },
    item: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '14px',
        color: '#ffffff',
        marginLeft: '20px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            marginLeft: "10px"
        }
    },
    itemFirst: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '14px',
        color: '#ffffff',
        marginLeft: '40px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            marginLeft: "10px"
        }
    },
    centerText: {
        fontFamily: 'Aeonik',
        fontWeight: 500,
        fontSize: '68px',
        color: '#ffffff',
        width: '95%',
        lineHeight: '90px',
        textAlign: 'center',
        marginLeft: "20px",

        [theme.breakpoints.down('xs')]: {
            fontSize: "60px",
            lineHeight: '60px',

        }
    },
    centerTextSub: {
        fontFamily: 'Aeonik',
        fontWeight: 500,
        fontSize: '35px',
        color: '#161616',

        marginTop: '20px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: "30px"
        }
    },
    centerTextSmall: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '15px',
        color: '#161616',
        marginTop: '20px',
        textAlign: 'center',

    },
    input: {
        '&::placeholder': {
            font: 'Aeonik',
            color: '#fff',
            fontSize: '21px',
        },
    },
    inputSubmit: {

        fontFamily: 'Aeonik',
        color: '#fff',
        fontSize: '18px',
        paddingTop: '7px',
        paddingLeft: '60px'
    },
    boxContainer: {
        display: 'inline-flex',
        height: '80px',
        width: '430px',
        backgroundColor: '#ff5043',
        borderRadius: '50px',
        borderColor: 'white',
        borderWidth: '2px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        marginTop: '80px',
        [theme.breakpoints.down('xs')]: {
            width: "330px"
        }
    },
    boxContainer2: {
        display: 'inline-flex',
        height: '80px',
        width: '430px',
        backgroundColor: '#ff5043',
        borderRadius: '50px',
        borderColor: 'white',
        borderWidth: '2px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        marginTop: '20px',
        [theme.breakpoints.down('xs')]: {
            width: "330px"
        }
    },
    boxContainerSubmit: {
        display: 'inline-flex',
        height: '40px',
        width: '180px',
        backgroundColor: '#161616',
        borderRadius: '50px',
        borderColor: '#161616',
        borderWidth: '2px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        marginTop: '20px',
        [theme.breakpoints.down('xs')]: {
            width: "180px",
            backgroundColor: "#161616",
            borderColor: "#161616"
        },
        cursor: 'pointer'
    },
    itemSecond: {
        marginRight: "10px",
        [theme.breakpoints.down('xs')]: {
            marginRight: "10px"
        }
    },
    text5: {
        color: "white",
        fontSize: "20px",
        fontFamily: "Aeonik"
    },
    bottomInnerContainer: {
        backgroundColor: "black",
        padding: "1.5rem 3rem",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px"
    },
    bottomUpperContainer: {
        paddingBottom: "-1rem",
        display: "block",
        width: "50%",
        [theme.breakpoints.down('xs')]: {
            display: "none",
        }
    },
    main: {
        backgroundImage: `url(${pattern2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "bottom",
        //paddingLeft:"6rem",
        [theme.breakpoints.down('xs')]: {
            backgroundSize: "contain"
        }
    },
    bottomImg: {
        width: "50%",
        paddingLeft: "10px",
        display: "block",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '110px',
        justifyContent: 'space-between',
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    buttonInnerContainer: {
        height: '34px',
        width: '34px',
        backgroundColor: '#ff5043',
        borderRadius: '19px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '10px',
        marginRight: '3px',
        marginTop: '3px',
        [theme.breakpoints.down('xs')]: {
            backgroundColor: "#ff5043"
        }

    },
    mobileUpperContainer: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block",
            marginTop: "1rem"
        }
    },
    mobileFooterContainer: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block",
            backgroundColor: "#161616",
            padding: "2rem 0.5rem",
            //marginTop:"-10px",
            zIndex: 1000000
        }
    },
    mobilefooterInnerContainer: {
        display: "flex",
        justifyContent: "space-around",

    },
    mobileContainerText: {
        color: "white",
        fontFamily: "Aeonik",
        textAlign: "center",
        marginTop: "5px"
    },
    footerimagemob: {
        width: "90%",
        display: "block",
        margin: "auto",
        zIndex: -1
    }
}));



function StayUpdated() {
    const classes = useStyles();

    return (
        <>
            <ThemeProvider theme={theme}>
                <ParallaxProvider>

                    <div style={{ backgroundColor: '#ff5043' }} id="contact" className={classes.main}>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '120px' }}>
                            <Typography className={classes.centerText}>Still, having second thoughts about?</Typography>
                            <Typography className={classes.centerTextSub}>Schedule a call with our ambassadors</Typography>

                            <Box border={1} className={classes.boxContainer}>
                                <TextField style={{

                                    paddingTop: '25px',
                                    paddingBottom: '20px',
                                    paddingLeft: '45px',
                                    paddingRight: "45px"
                                }}
                                    placeholder="Your Email address"
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { input: classes.input }
                                    }}>
                                </TextField>

                                {/* <div style={{
                                height: '67px',
                                width: '68px',
                                backgroundColor: '#161616',
                                borderRadius: '35px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '20px',
                                marginRight: '6px',
                                marginTop: '7px'
                            }}>
                                <ArrowForwardIcon style={{ fill: 'whitesmoke', height: '35px', width: '50px', cursor: 'pointer' }} />
                            </div> */}
                            </Box>

                            <Box border={1} className={classes.boxContainer2}>
                                <TextField style={{

                                    paddingTop: '25px',
                                    paddingBottom: '20px',
                                    paddingLeft: '45px',
                                    paddingRight: "45px"
                                }}
                                    placeholder="Your phone number"
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { input: classes.input }
                                    }}>
                                </TextField>

                                {/* <div style={{
                                height: '67px',
                                width: '68px',
                                backgroundColor: '#161616',
                                borderRadius: '35px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '20px',
                                marginRight: '6px',
                                marginTop: '7px'
                            }}>
                                <ArrowForwardIcon style={{ fill: 'whitesmoke', height: '35px', width: '50px', cursor: 'pointer' }} />
                            </div> */}
                            </Box>
                            <Box border={1} className={classes.boxContainerSubmit}>
                                <Typography className={classes.inputSubmit}>Submit</Typography>

                                <div className={classes.buttonInnerContainer} >
                                    <ArrowForwardIcon style={{ fill: 'whitesmoke', height: '19px', width: '25px', cursor: 'pointer' }} />
                                </div>
                            </Box>
                            {/* <Typography className={classes.centerTextSmall}>Definitely don't press this big red button</Typography> */}
                            <div style={{}} className={classes.footerContainer}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* <Typography className={classes.headitem}>Brocast</Typography> */}
                                    <Typography className={classes.itemFirst}>Twitter</Typography>
                                    <Typography className={classes.item}>Facebook</Typography>
                                    <Typography className={classes.item}>Instagram</Typography>
                                    <Typography className={classes.item}>Linkedin</Typography>
                                </div>

                                <img src={FooterImg} className={classes.bottomImg} />

                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    {/* <Typography className={classes.headitem}>Brocast</Typography> */}
                                    <Typography className={classes.itemFirst}>Privacy</Typography>
                                    <Typography className={[classes.item, classes.itemSecond]}>© Brocast Entertainment 2021</Typography>
                                </div>
                            </div>
                            <div className={classes.mobileUpperContainer}>
                                <img src={FooterImgMob} className={classes.footerimagemob} />
                                <div className={classes.mobileFooterContainer}>

                                    <div className={classes.mobilefooterInnerContainer}>
                                        <Typography className={classes.itemFirst}>Twitter</Typography>
                                        <Typography className={classes.item}>Facebook</Typography>
                                        <Typography className={classes.item}>Instagram</Typography>
                                        <Typography className={classes.item}>Linkedin</Typography>
                                    </div>
                                    <Typography className={classes.mobileContainerText}>© Brocast Entertainment 2021</Typography>
                                </div>
                            </div>
                        </div>

                    </div>
                </ParallaxProvider>
            </ThemeProvider>
        </>
    );
}

export default StayUpdated;
