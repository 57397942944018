import React, { useState, useEffect } from 'react'
import home1 from '../assets/arjit1.jpeg'
import home2 from '../assets/home-pic5.jpg'
import { Typography, Grid, Divider } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { makeStyles } from '@material-ui/core/styles'
import Button from './Button';
import pattern1 from '../assets/pattern1.png';
import Aos from 'aos';

const about1 = 'Arjit build is his whole career closely understanding the marketing and brand psychology behind some of the well-known Startups of India. Most recently he has co-founded his own travel tech company making it a well-known solution provider in the Indian travel sector. Before that, he has working experience with 2 big unicorns of India and numerous startups as a marketing consultant.'
const about2 = 'Marcel was one of the first employees at Wunderlist and joined Microsoft with the acquisition in 2015. As a program manager at Microsoft, he led globally distributed teams on product development and customer engagement projects.'

const useStyles = makeStyles((theme) => ({
  heading: {
    font: "Aeonik",
    color: "#ff5043",
    fontSize: "84px",
    marginLeft: "6rem",
    transition: "opacity 1s ease-in-out",
    paddingTop: '80px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: "0rem",
      fontSize: "60px",
      textAlign: "center"
    }
  },
  image: {
    width: "350px",
    height: "100%",
    borderRadius: "50%",
    [theme.breakpoints.down('xs')]: {
      display: "block",
      margin: "1rem auto",

    }
  },
  founderName: {
    fontFamily: "Aeonik",
    color: "#ababab",
    fontSize: "30px",
    marginLeft: "6rem",
    cursor: "pointer",
    transition: 'transform .6s cubic-bezier(.32,.94,.6,1),color .6s cubic-bezier(.32,.94,.6,1)',
    transformOrigin: "center left",
    justifyContent: "center",
    marginTop: "6rem",
    '&:hover': {
      color: "#161616"
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: "0rem",
      marginTop: 0,
    }
  },
  foundername1: {
    fontFamily: "Aeonik",
    color: "#161616",
    fontSize: "38px",
    marginLeft: "6rem",
    cursor: "pointer",
    transition: 'transform .6s cubic-bezier(.32,.94,.6,1),color .6s cubic-bezier(.32,.94,.6,1)',
    transformOrigin: "center left",
    color: "#161616",
    transform: "scale(1.15)",
    marginTop: "3rem",
    [theme.breakpoints.down('xs')]: {
      marginLeft: "0rem",
      textAlign: "center",
      marginRight: "3rem",
      marginTop: 0,
    }
  },
  divider: {
    //width:"100px",
    transition: 'width 2s',
    height: "2px",
    background: "gray",
    marginLeft: "6rem",
    //'&:hover':{
    width: "0px",
    transformOrigin: "center left",
    [theme.breakpoints.down('xs')]: {
      marginLeft: "1rem"
    }
  },
  divider1: {
    width: "50%",
    transition: 'width 2s',
    height: "2px",
    background: "#ff5043",
    marginLeft: "6rem",
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      marginLeft: "5.5rem",
      width: "50%"
    }
  },
  // main:{
  //     background:'linear-gradient(174deg, #ffffff 83%, #161616 17%)',
  //     height:'1100px',
  //     marginTop:"-25rem"
  // },
  about: {
    fontFamily: 'Aeonik',
    color: "#000000",
    fontSize: "58px",
    fontWeight: 700,
    textAlign: "justify"
  },
  abouttext: {
    fontFamily: 'Aeonik',
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26.4px",
    color: "#161616",
    paddingLeft: "1rem",
    marginTop: "1rem",
    borderLeft: "10px solid #ff5043",
    textAlign: "justify",
    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
      textAlign: "justify"
    }
  },
  aboutgrid: {
    paddingRight: "11rem",
    paddingTop: "3rem",
    marginTop: "4rem",
    [theme.breakpoints.down('xs')]: {
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingTop: "2rem"
    }
  },
  main: {
    //background: 'linear-gradient(174deg, #ffffff 83%, #161616 17%)',
    height: '100%',
    transition: "height 2s",
    backgroundImage: `url(${pattern1})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    // '&:hover':{
    // height:"100vh",
    //transition:"height 2s",

    //animation:`$example 2s`,
    //AnimationTimeingFunction:"linear",
    //background: 'linear-gradient(180deg, #ffffff 83%, #161616 17%)',
    //marginTop: "-15rem",
    paddingBottom: "8rem",
    paddingTop: "4rem",
    //},
    [theme.breakpoints.down('xs')]: {
      height: "100%",
      backgroundImage: "none",
      // marginTop: "-20rem",
      zIndex: 100000000
    }
  },

  '@keyframes example': {
    "0%": {
      background: 'linear-gradient(174deg, #ffffff 83%, #161616 17%)',
    },
    "5%": {
      background: 'linear-gradient(174.3deg, #ffffff 83%, #161616 17%)',
    },
    "10%": {
      background: 'linear-gradient(174.6deg, #ffffff 83%, #161616 17%)',
    },
    "15%": {
      background: 'linear-gradient(174.9deg, #ffffff 83%, #161616 17%)',
    },
    "20%": {
      background: 'linear-gradient(175.2deg, #ffffff 83%, #161616 17%)',
    },
    "25%": {
      background: 'linear-gradient(175.5deg, #ffffff 83%, #161616 17%)',
    },
    "30%": {
      background: 'linear-gradient(175.8deg, #ffffff 83%, #161616 17%)',
    },
    "35%": {
      background: 'linear-gradient(176.1deg, #ffffff 83%, #161616 17%)',
    },
    "40%": {
      background: 'linear-gradient(176.4deg, #ffffff 83%, #161616 17%)',
    },
    "45%": {
      background: 'linear-gradient(176.7deg, #ffffff 83%, #161616 17%)',
    },
    "50%": {
      background: 'linear-gradient(177deg, #ffffff 83%, #161616 17%)',
    },
    "55%": {
      background: 'linear-gradient(177.3deg, #ffffff 83%, #161616 17%)',
    },
    "60%": {
      background: 'linear-gradient(177.6deg, #ffffff 83%, #161616 17%)',
    },
    "65%": {
      background: 'linear-gradient(177.9deg, #ffffff 83%, #161616 17%)',
    },
    "70%": {
      background: 'linear-gradient(178.2deg, #ffffff 83%, #161616 17%)',
    },
    "75%": {
      background: 'linear-gradient(178.5deg, #ffffff 83%, #161616 17%)',
    },
    "80%": {
      background: 'linear-gradient(178.8deg, #ffffff 83%, #161616 17%)',
    },
    "85%": {
      background: 'linear-gradient(179.1deg, #ffffff 83%, #161616 17%)',
    },
    "90%": {
      background: 'linear-gradient(179.4deg, #ffffff 83%, #161616 17%)',
    },
    "95%": {
      background: 'linear-gradient(179.7deg, #ffffff 83%, #161616 17%)',
    },
    "100%": {
      background: 'linear-gradient(180deg, #ffffff 83%, #161616 17%)',
    },

  },
  image1: {
    width: "auto",
    height: "500px",
    borderRadius: "15px",
    //marginLeft: "6rem",
    //marginTop:"-2rem",
    paddingLeft: "40px",
    [theme.breakpoints.down('xs')]: {
      display: "block",
      // marginLeft: "auto",
      //marginRight: "auto",
      height: "auto",
      width: "90%"
    }
  },
  founderText: {
    fontFamily: "Aeonik",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "33.6px",
    color: "#ff5043",
  }
}))

function Founders() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  }, []);

  const [image, setImage] = useState(home1);
  const [about, setAbout] = useState(about1);
  const [word, setword] = useState(true);
  const [word2, setword2] = useState(false);
  const [word3, setword3] = useState(false);
  const [word4, setword4] = useState(false);

  const hello = (i, text) => {
    setImage(i);
    setAbout(text);

  }
  const toggle = (e) => {
    setword(true);
    setword2(false);
    setword3(false);
    setword4(false);
  }
  const toggle2 = (e) => {
    setword(false);
    setword2(true);
    setword3(false);
    setword4(false);
  }
  const toggle3 = (e) => {
    setword(false);
    setword2(false);
    setword3(true);
    setword4(false);
  }
  const toggle4 = (e) => {
    setword(false);
    setword2(false);
    setword3(false);
    setword4(true);
  }
  return (
    <ParallaxProvider>
      <div className={classes.main} id="founders">
        {/*<Typography className={classes.heading}>Founder</Typography>*/}
        <Grid container >
          <Grid item xs={12} sm={5}>

            {/*<Typography className={word ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home1, about1); toggle()}} style={{paddingBottom:'20px'}}
                    >
                        Arjit Singh
                        
                    </Typography>
    <div className={word ? classes.divider1 : classes.divider}></div>*/}
            {/*<Typography className={word2 ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home2, about2); toggle2()}} style={{paddingBottom:'20px'}}
                    >
                        Marcel Käding
                    </Typography>
                    <div className={word2 ? classes.divider1 : classes.divider}></div>
                    <Typography className={word3 ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home1, about1); toggle3()}} style={{paddingBottom:'20px'}}
                    >
                        Steffen Kiedel
                    </Typography>
                    <div className={word3 ? classes.divider1 : classes.divider}></div>
                    <Typography className={word4 ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home2, about2); toggle4()}} style={{paddingBottom:'20px'}}
                    >
                        Ben Kubota
                    </Typography>
                    <div className={word4 ? classes.divider1 : classes.divider}></div>*/}
            <img src={image} className={classes.image1} />

          </Grid>

          <Grid item xs={12} sm={7} className={classes.aboutgrid}>
            <div data-aos="zoom-in">
              <Typography className={classes.founderText}>Founder</Typography>
              <Typography className={classes.about}>Arjit Singh</Typography>
              <Typography className={classes.abouttext}>{about}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </ParallaxProvider>
  )
}

export default Founders
