import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button';


const useStyles = makeStyles((theme) => ({
    headitem: {
        font: 'Aeonik',
        fontWeight: 700,
        fontSize: '27px',
        color: '#ffffff',
        marginTop: '30px',
        marginLeft: '40px'
    },
    item: {
        font: 'Aeonik',
        fontWeight: 400,
        fontSize: '14px',
        color: '#ffffff',
        marginTop: '43px',
        marginLeft: '20px',
        cursor: 'pointer'
    },
    itemFirst: {
        font: 'Aeonik',
        fontWeight: 400,
        fontSize: '14px',
        color: '#ffffff',
        marginTop: '43px',
        marginLeft: '40px',
        cursor: 'pointer'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: '100px',
        paddingLeft: '200px',
        paddingRight: '200px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: 0
        }
    },
    centerText: {
        font: 'Aeonik',
        fontWeight: 500,
        fontSize: '90px',
        color: '#ff5043',
        marginBottom: '120px',
        textAlign: 'center',
        lineHeight: '120px',
        marginTop: '100px',
        [theme.breakpoints.down('xs')]: {
            fontSize: "40px",
            marginBottom: "50px",
            marginTop: "50px",
            lineHeight: '60px',

        }
    },
    centerTextSmall: {
        font: 'Aeonik',
        fontWeight: 400,
        fontSize: '30px',
        color: '#ffffff',
        marginBottom: '15px',
        textAlign: 'left',
        lineHeight: '35px',
        paddingBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            fontSize: "25px",
            lineHeight: "30px"
        }

    },
    main: {
        background: 'linear-gradient(174deg, #161616 83%, #ffffff 17%)',
        height: '1200px',
        transition: "height 2s",
        '&:hover': {
            height: "1000px",
            transition: "height 2s",

            animation: `$example 1s`,
            AnimationTimeingFunction: "linear",
            background: 'linear-gradient(180deg, #161616 83%, #ffffff 17%)',
        },
        [theme.breakpoints.down('xs')]: {
            height: "110vh"
        }
    },

    '@keyframes example': {
        "0%": {
            background: 'linear-gradient(174deg, #161616 83%, #ffffff 17%)',
        },
        "5%": {
            background: 'linear-gradient(174.3deg, #161616 83%, #ffffff 17%)',
        },
        "10%": {
            background: 'linear-gradient(174.6deg, #161616 83%, #ffffff 17%)',
        },
        "15%": {
            background: 'linear-gradient(174.9deg, #161616 83%, #ffffff 17%)',
        },
        "20%": {
            background: 'linear-gradient(175.2deg, #161616 83%, #ffffff 17%)',
        },
        "25%": {
            background: 'linear-gradient(175.5deg, #161616 83%, #ffffff 17%)',
        },
        "30%": {
            background: 'linear-gradient(175.8deg, #161616 83%, #ffffff 17%)',
        },
        "35%": {
            background: 'linear-gradient(176.1deg, #161616 83%, #ffffff 17%)',
        },
        "40%": {
            background: 'linear-gradient(176.4deg, #161616 83%, #ffffff 17%)',
        },
        "45%": {
            background: 'linear-gradient(176.7deg, #161616 83%, #ffffff 17%)',
        },
        "50%": {
            background: 'linear-gradient(177deg, #161616 83%, #ffffff 17%)',
        },
        "55%": {
            background: 'linear-gradient(177.3deg, #161616 83%, #ffffff 17%)',
        },
        "60%": {
            background: 'linear-gradient(177.6deg, #161616 83%, #ffffff 17%)',
        },
        "65%": {
            background: 'linear-gradient(177.9deg, #161616 83%, #ffffff 17%)',
        },
        "70%": {
            background: 'linear-gradient(178.2deg, #161616 83%, #ffffff 17%)',
        },
        "75%": {
            background: 'linear-gradient(178.5deg, #161616 83%, #ffffff 17%)',
        },
        "80%": {
            background: 'linear-gradient(178.8deg, #161616 83%, #ffffff 17%)',
        },
        "85%": {
            background: 'linear-gradient(179.1deg, #161616 83%, #ffffff 17%)',
        },
        "90%": {
            background: 'linear-gradient(179.4deg, #161616 83%, #ffffff 17%)',
        },
        "95%": {
            background: 'linear-gradient(179.7deg, #161616 83%, #ffffff 17%)',
        },
        "100%": {
            background: 'linear-gradient(180deg, #161616 83%, #ffffff 17%)',
        },

    },
}));

function About() {
    const classes = useStyles();
    const [h, seth] = useState(false);
    return (
        <>

            <ParallaxProvider>

                <div className={h ? classes.main1 : classes.main} id="about">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography className={classes.centerText}>Stories Worth Sharing</Typography>
                        <div className={classes.container}>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.centerTextSmall}>As Wunderlist shuts down, <br />its founder announces a new <br />productivity app called Brocast</Typography>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.centerTextSmall}>Wunderlist founder <br />announces a new productivity<br />app called Brocast</Typography>

                                </Grid>
                            </Grid>
                        </div>

                    </div>

                </div>
            </ParallaxProvider>
        </>
    );
}

export default About;
