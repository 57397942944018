import React from 'react';
import { StylesProvider, Typography } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { makeStyles } from '@material-ui/core/styles';
import Home from './components/Home'
import Button from './components/Button';
import Demo from './components/Demo'
import { BrowserRouter, Route, Switch, Router, HashRouter } from 'react-router-dom';
import SmoothScroll from './component2/SmoothScroll'

function App() {

  return (
    
    <div >

      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/button" component={Button} />
        </Switch>
      </BrowserRouter>

    </div>
    
  );
}

export default App;
