import React,{useState} from 'react'
import home1 from '../assets/home-pic3.png'
import home2 from '../assets/home-pic5.jpg'
import { Typography, Grid, } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import pattern1 from '../assets/pattern1.png'
import pattern3 from '../assets/pattern3.png'

const about1 = 'Everyone one loves stories and everyone does connect with them. When it comes to disrupting brand stories and relatable brand vibe, we breathe for it, So,you are just atthe right place.';
const about2 = "No matter what's your budget, what's your content requirement, what's your potential geographical area to tap on. We exel it anyway, it has an unforgettable impact.";
const about3 = "Spending a fortune on google ads still couldn't get sales number, We do build go to marketing strategies from scratch along with e-commerce optimization strategies for startups"
const about4 = "We support artists, influencers and brands to produce amazing content and make sure to make it viral. Because why not !! we are hell passionate about it."
const useStyles = makeStyles(theme => ({
    heading:{
        fontFamily:"Aeonik",
        color:"#ff5043",
        fontSize:"84px",
        marginLeft:"6rem",
        transition:"opacity 1s ease-in-out",
        lineHeight:'90px',
        marginTop:'100px',
        [theme.breakpoints.down('xs')]:{
            marginLeft:"1rem",
            fontSize:"44px",
            lineHeight:"60px"
        }
    },
    benefits:{
        fontFamily:"Aeonik",
        color:"#ababab",
        fontSize:"16px",
        marginBottom:'12px',
        fontWeight:600,
        marginLeft:'25px',
        
    },
    benefitsPoints:{
        fontFamily:"Aeonik",
        color:"#161616",
        fontSize:"15px",
    },
    image:{
        width:"300px",
        height:"300px",
        
    },
    founderName:{
        fontFamily:"Aeonik",
        color:"grey",
        fontSize:"34px",
        marginLeft:"3rem",
        cursor:"pointer",
        transition: 'transform 1s cubic-bezier(.32,.94,.6,1),color .6s cubic-bezier(.32,.94,.6,1)',
        transformOrigin :"center left",
        marginTop:"10px",
        paddingBottom:"10px",
        borderBottom:"2px solid gray",
        
        '&:hover':{
            
            color:"black",
            borderBottom:"2px solid black",

        },
        [theme.breakpoints.down('xs')]:{
            fontSize:"24px",
            marginLeft:"1rem",
            marginTop:"1rem",
            marginRight:"1rem"
        }
    },
    foundername1:{
        fontFamily:"Aeonik",
        color:"grey",
        fontSize:"34px",
        marginLeft:"3rem",
        cursor:"pointer",
        transition: 'transform .6s cubic-bezier(.32,.94,.6,1),color .6s cubic-bezier(.32,.94,.6,1)',
        transformOrigin :"center left",
        color:"black",
        //marginTop:"10px",
           transform:"scale(1.15)" ,
           [theme.breakpoints.down('xs')]:{
            fontSize:"24px",
            marginLeft:"1rem",
            marginTop:"1rem"
        }
    },
    divider:{
        //width:"100px",
        transition: 'left width 2s ease-out',
        height:"2px",
        background:"gray",
        marginLeft:"3rem",
        //'&:hover':{
        width:"0px",
        transformOrigin :"center left",
        //background:"black",
       // marginBottom:"10px",
        //marginTop:"10px",
        //}
        [theme.breakpoints.down('xs')]:{
            marginLeft:"1rem",
            marginRight:"1rem"
        }
    },
    divider1:{
        width:"90%",
        transition: 'width 2s',
        
        height:"2px",
        background:"#ff5043",
        marginLeft:"3rem",
        //'&:hover':{
           //marginBottom:"10px",
           marginTop:"10px",
        [theme.breakpoints.down('xs')]:{
                marginLeft:"1rem",
                marginRight:"1rem",
                width:"91%"
        }
        
    },
    main: {
        //background: 'linear-gradient(180deg, #ff5043 100%)',
        height: '100%',
        overflow:"hidden",
        backgroundImage:`url(${pattern1})`,
        backgroundSize:"contain",
        backgroundRepeat:"no-repeat",
        backgroundPositionX:"right",
        //transition:"height 2s",
        //'&:hover':{
          //height:"100vh",
        //transition:"height 2s",
        //background: 'linear-gradient(180deg, #ff5043 100%)',
        //},
        [theme.breakpoints.down('xs')]:{
          height:"100%",
          backgroundImage:"none"
        }
      },
      main1:{
        height: '100%',
        overflow:"hidden",
        backgroundImage:`url(${pattern3})`,
        backgroundSize:"contain",
        backgroundRepeat:"no-repeat",
        backgroundPositionY:"top",
        backgroundSize:"450px",
        [theme.breakpoints.down('xs')]:{
            backgroundImage:"none"
        }
      },
    button1:{
        marginLeft:"3rem",
        marginTop:"1rem",
        [theme.breakpoints.down('xs')]:{
            marginLeft:"1rem"
        }
    },
    container:{
        paddingLeft:'100px', 
        paddingRight:'200px', 
        paddingBottom:'150px',
        [theme.breakpoints.down('xs')]:{
            paddingLeft:0,
            paddingRight:0,
            paddingBottom:"50px"
        }
    },
    secondContainer:{
        marginTop:"5rem",
        [theme.breakpoints.down('xs')]:{
            marginTop:"1rem"
        }
    },
    aboutText:{
        fontFamily:"Aeonik",
        fontSize:"20px",
        marginTop:"4rem",
        textAlign:"justify",
        fontWeight:500,
        color:"0f0f0f",
        lineHeight:1.4,
        marginRight:"10px",
        paddingLeft:"10px",
        borderLeft:"5px solid #ff5043",
        [theme.breakpoints.down('xs')]:{
            marginLeft:"1rem",
            marginRight:"1rem",
            marginTop:0,
            fontSize:"18px"
        }
    }
}))

function People() {
    const classes = useStyles();
    const [image, setImage] = useState(home1);
    const [about , setAbout] = useState(about1);
    const [word, setword] = useState(true);
    const [word2, setword2] = useState(false);
    const [word3, setword3] = useState(false);
    const [word4, setword4] = useState(false);
    
    
    const hello = (i, text) => {
        setImage(i);
        setAbout(text);
        
    }
    const toggle = (e) => {
        setword(true);
        setword2(false);
        setword3(false);
        setword4(false);
        setAbout(about1);
    }
    const toggle2 = (e) => {
        setword(false);
        setword2(true);
        setword3(false);
        setword4(false);
        setAbout(about2);

    }
    const toggle3 = (e) => {
        setword(false);
        setword2(false);
        setword3(true);
        setword4(false);
        setAbout(about3);

    }
    const toggle4 = (e) => {
        setword(false);
        setword2(false);
        setword3(false);
        setword4(true);
        setAbout(about4);

    }
    return (
        <div className={classes.main1}>
        <div className={classes.main} id="people">
            <Typography className={classes.heading}>What else we do</Typography>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <Grid container  className={classes.container}>
                <Grid item xs={12} sm={6} style={{display:'flex', flexDirection:'column', marginTop:'50px'}}>
                    <div style={{display:"none"}}>
                    <Typography className={classes.benefits}>Benefits</Typography>
                    <ul className={classes.benefitsPoints}>
                        <li> Remote-first company setup</li>
                        <li> Competitive compensation / equity package</li>
                        <li> Healthy work-life balance</li>
                        <li> Parent-friendly company culture</li>
                        <li> Educational stipend</li>
                        <li> 30-days vacation</li>
                        <li> Top-of-the-line equipment</li>
                        <li> Experienced, distributed, and diverse team</li>
                    </ul>
                    </div>
                    <Typography className={classes.aboutText}>{about}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.secondContainer}>

                    <Typography className={word ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home1, about1); toggle()}} 
                    >
                        Brand Stories
                        
                    </Typography>
                    <div className={word ? classes.divider1 : classes.divider}></div>
                    <Typography className={word2 ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home2, about2); toggle2()}} 
                    >
                        Content generators
                    </Typography>
                    <div className={word2 ? classes.divider1 : classes.divider}></div>
                    <Typography className={word3 ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home1, about1); toggle3()}}
                    >
                        E-commerce
                    </Typography>
                    <div className={word3 ? classes.divider1 : classes.divider}></div>
                    <Typography className={word4 ? classes.foundername1 : classes.founderName}
                        onClick={() => {hello(home1, about1); toggle4()}}
                    >
                        Production/distribution
                    </Typography>
                    <div className={word4 ? classes.divider1 : classes.divider}></div>
                    <div className={classes.button1}>
                    {/* <Button name="View all roles"/> */}
                    </div>
                </Grid>
            </Grid>
            </div>
        </div>
        </div>
    )
}

export default People
