import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    buttonText:{
        transform:"rotateY(0deg)",
        '&:hover':{
            transform:"rotateY(360deg)"
        }
    }
}));

function Button({name}) {
    const classes = useStyles();

    return (
        <>
            <div style={{
                display: 'inline-flex',
                height: '50px',
                width:'160px',
                backgroundColor: '#000',
                borderRadius: '35px',
                flexDirection: 'row',
                // marginTop: '50px',
                // marginLeft: '100px',
                justifyContent:'space-between',
                cursor:'pointer'
            }}>
                <Typography style={{
                    font: 'Aeonik',
                    color: '#fff',
                    paddingTop: '12px',
                    paddingBottom: '10px',
                    paddingLeft: '15px',
                    fontSize: '1rem',
                    textAlign:"center"
                }}  className={classes.buttonText}>{name}</Typography>
                <div style={{
                    height: '32px',
                    width: '32px',
                    backgroundColor: 'tomato',
                    borderRadius: '35px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    marginLeft:'20px',
                    marginRight:'6px',
                    marginTop:'10px'
                }}>
                <ArrowForwardIcon style={{fill:'whitesmoke', height:'20px', width:'25px', }}/>
                </div>
            </div>
        </>
    );
}

export default Button;
