import React, { useState } from 'react';
import { Typography, Grid, Container } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button';
import vector2 from '../assets/vector2.svg';


const useStyles = makeStyles((theme) => ({
    headitem: {
        fontFamily: 'Aeonik',
        fontWeight: 700,
        fontSize: '27px',
        color: '#ffffff',
        marginTop: '30px',
        marginLeft: '40px'
    },
    item: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '14px',
        color: '#ffffff',
        marginTop: '43px',
        marginLeft: '20px',
        cursor: 'pointer'
    },
    itemFirst: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '14px',
        color: '#ffffff',
        marginTop: '43px',
        marginLeft: '40px',
        cursor: 'pointer'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: '100px',
        paddingLeft: '150px',
        paddingRight: '12rem',
        //overflowY:'scroll',

        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: 0
        }
    },
    centerText: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '50px',
        color: '#fff',
        marginBottom: '50px',
        // textAlign: 'right',
        //width: '100%',
        lineHeight: '55px',
        marginTop: '100px',
        //position: "-webkit-sticky",
        //position: "sticky",
        //top: 0,
        zIndex: "-10000000",
        marginLeft: "6rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: "35px",
            marginBottom: "50px",
            marginTop: "50px",
            textAlign: "left",
            marginLeft: "1rem",
            lineHeight: '45px',
            // width:"100%"
        },

    },
    centerTextSub: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '25px',
        color: '#fff',
        marginBottom: '120px',
        // textAlign: 'right',
        //width: '100%',
        lineHeight: '30px',
        marginTop: '10px',
        //position: "-webkit-sticky",
        //position: "sticky",
        //top: 0,
        zIndex: "-10000000",
        marginLeft: "6rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: "30px",
            marginBottom: "50px",
            marginTop: "50px",
            textAlign: "left",
            marginLeft: "1rem"
        },

    },
    centerTextSmall: {
        fontFamily: 'Aeonik',
        fontWeight: 400,
        fontSize: '30px',
        color: '#ffffff',
        marginBottom: '15px',
        textAlign: 'left',
        lineHeight: '35px',
        paddingBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            fontSize: "25px",
            lineHeight: "30px",
            paddingRight: "10px",
            paddingLeft: "1rem"
        }

    },
    main: {
        //background: 'linear-gradient(180deg, #161616 83%, #ffffff 17%)',
        backgroundColor: "#161616",
        height: '1700px',
        width: "100%",
        outline: "none",
        paddingBottom: "3rem",
        [theme.breakpoints.down('xs')]: {

            height: "2100px",
            // paddingBottom:0,
            // overflowY:"auto"
        }
    },
    textCont2: {
        position: "sticky",

        top: "10rem",
        //left:"3rem"
        [theme.breakpoints.down('xs')]: {
            position: "block"
        }
    },
    textCont1: {
        position: "fixed",
        top: "7rem",
        //left:"3rem",
        [theme.breakpoints.down('xs')]: {
            position: "sticky"
        }
    },
    gridcontainer: {
        height: "1500px",
        [theme.breakpoints.up('xl')]: {
            height: "1000px"
        },
        [theme.breakpoints.down('xs')]: {
            height: "100%"
        }
    },
    centertext1: {
        fontFamily: "Aeonik",
        fontWeight: 400,
        fontStyle: "normal",
        // paddingRight:"10px",
        textAlign: "justify",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "1rem",
            paddingRight: "1rem"
        }
    },
    vector: {
        width: "100%",
        height: "250px",
        marginTop: "-5rem",
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            //paddingLeft:"1rem",
            //paddingRight:"1rem",
            height: "200px"
        }
    }
}));

function Information() {
    const classes = useStyles();
    const [fix, setfix] = useState(false);
    const changefixed = () => {
        if (window.scrollY > 900 && window.scrollY < 2900) {
            setfix(true);
        }
        else {
            setfix(false);
        }
    }
    window.addEventListener('scroll', changefixed);

    return (
        <>



            <div className={classes.main} id="info">
                <div >
                    <Grid container >

                        <Grid item xs={12} sm={6} className={classes.gridcontainer} >

                            <div className={classes.textCont2} >
                                <Typography className={classes.centerText} >We build brands with values and designed experience</Typography>
                                <Typography className={classes.centerTextSub} >Get brand deals and sponsors at<span style={{ color: '#ff5043' }}> 0% commission</span>
                                    <br /> for your content.
                                    </Typography>
                                <div>
                                    <img src={vector2} className={classes.vector} />
                                </div>
                            </div>

                        </Grid>

                        <Grid item xs={12} sm={6} style={{ overflowX: "hidden", height: "100%" }}>
                            <Container className={classes.container}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} style={{ marginBottom: '50px' }}>
                                        <Typography className={classes.centerTextSmall}>Portfolio caretaker</Typography>
                                        <Typography style={{ color: 'white' }} className={classes.centertext1}>When we are there, no need to worry about managing your
                                        portfolios and representations. We believe every artist and influencer should spend the
                                        majority of their time creating effective content and engagements. Brocast will take care
                                        of your portfolio throughout the association and which will make it easy to attract more
                                        brands to the association.
                                            </Typography>

                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ marginBottom: '50px' }}>
                                        <Typography className={classes.centerTextSmall}>Influencer outreach</Typography>
                                        <Typography style={{ color: 'white' }} className={classes.centertext1}>We are the first and only associates who connect Nano and
                                        micro-influencers to well-known brands at 0% commission model. Yes !! you heard right,
                                        now monetize your content without giving any commission to any agency. Now be the
                                        face of well-known brands by 100% transparency.
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ marginBottom: '50px' }}>
                                        <Typography className={classes.centerTextSmall}>Digital Advertising &<br />Brand identity </Typography>
                                        <Typography style={{ color: 'white' }} className={classes.centertext1}>We are a team of creative young guys who
                                        have the ability to plan and produce the most, impacting and productive advertisements
                                        for you and manage your brand values and principles which can directly relate to your
                                        customers.
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ marginBottom: '50px' }}>
                                        <Typography className={classes.centerTextSmall}>Social media management</Typography>
                                        <Typography style={{ color: 'white' }} className={classes.centertext1}>We are moving towards digital business and the major
                                        problem startups face is effective social media strategies and campaigns. It’s not just
                                        about posting stuff, it’s about getting engaged with your customers and audience and
                                        making them a potential lead towards your sale. We can make your social media game
                                        superior like never before.
                                            </Typography>

                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ marginBottom: '450px' }}>
                                        <Typography className={classes.centerTextSmall}>Startup Branding/Marketing Guru</Typography>
                                        <Typography style={{ color: 'white' }} className={classes.centertext1}>Starting a new business is not just about an idea or
                                        product, in this tough competition industry now it’s all about marketing and branding.
                                        Don’t spend your money on marketing or hiring CMO, we have a bunch of markers who
                                        can build your marketing strategies. So, now focus on your product, your product will
                                        communicate by brocast.
                                            </Typography>

                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>



                </div>

            </div>

        </>
    );
}

export default Information;
