import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, ThemeProvider } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Founders from './Founders'
import About from './About';
import People from './People';
import StayUpdated from './StayUpdated';
import Button from './Button';
import Information from './Information';
import clsx from 'clsx';
import web from '../assets/main1.png'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Link } from 'react-scroll';
import OurThoughts from './OurThoughts';
import SmoothS from '../component2/SmoothScroll'
import pattern4 from '../assets/pattern4.svg'
import pattern5 from '../assets/pattern5.svg'
import logo1 from '../assets/logo1.png'
import logo2 from '../assets/logo2.png'
import Particles from 'react-particles-js';
import bottomLeft from '../assets/down.png'
import top from '../assets/up.png'
import Aos from 'aos';

const ttheme = createMuiTheme({
  overrides: {
    MuiContainer: {
      root: {
        marginLeft: 0
      }

    }
  }
})

const useStyles = makeStyles((theme) => ({
  headitem: {
    fontFamily: 'Aeonik',
    fontWeight: 700,
    fontSize: '27px',
    color: '#ffffff',
    marginTop: '30px',
    marginLeft: '40px'
  },
  textcolor1: {
    color: '#000000',
  },
  textcolor: {

    color: '#ffffff',

  },
  textcolor2: {

    color: '#ff5043',

  },
  item: {
    fontFamily: 'Aeonik',
    fontWeight: 400,
    fontSize: '14px',
    // color: '#ffffff',
    marginTop: '43px',
    marginLeft: '20px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  itemFirst: {
    fontFamily: 'Aeonik',
    fontWeight: 400,
    fontSize: '14px',
    color: '#ffffff',
    marginTop: '43px',
    marginLeft: '40px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  itemFirst1: {
    fontFamily: 'Aeonik',
    fontWeight: 400,
    fontSize: '14px',
    color: '#000000',
    marginTop: '43px',
    marginLeft: '40px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  itemFirst2: {
    fontFamily: 'Aeonik',
    fontWeight: 400,
    fontSize: '14px',
    color: '#ff5043',
    marginTop: '43px',
    marginLeft: '40px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  centerText: {
    fontFamily: 'Aeonik',
    fontWeight: 600,
    fontSize: '58px',
    color: '#ffffff',
    marginBottom: '25px',
    textAlign: 'left',
    lineHeight: '75px',
    letterSpacing: '1px',
    marginLeft: "6rem",
    [theme.breakpoints.down('xs')]: {
      fontSize: "30px",
      lineHeight: "70px"
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "55px",
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '60px',
    }
  },
  centerTextSmall: {
    fontFamily: 'Aeonik',
    fontWeight: 400,
    fontSize: '30px',
    color: '#ffffff',
    marginBottom: '15px',
    textAlign: 'left',
    marginLeft: "6rem",
    [theme.breakpoints.only('lg')]: {
      fontSize: "25px"
    }
  },

  main: {
    background: 'linear-gradient(174deg, #ff5043 83%, #161616 17%) ',
    zIndex: 1000000,
    height: '100vh',
    // transition: "height 2s",
    paddingBottom: "5.9rem",
    // '&:hover': {
    //   height: "100vh",
    //   transition: "height 2s",

    //   animation: `$example 2s`,
    //   AnimationTimeingFunction: "linear",
    //   background: 'linear-gradient(174deg, #ff5043 83%, #161616 17%) ',
    //   outline: "none"
    // },
    [theme.breakpoints.down('xs')]: {
      height: "100%",
      paddingBottom: "7rem",

    }
  },

  '@keyframes example': {
    "0%": {
      background: 'linear-gradient(174deg, #ff5043 83%, #161616 17%)',
    },
    "5%": {
      background: 'linear-gradient(174.3deg, #ff5043 83%, #161616 17%)',
    },
    "10%": {
      background: 'linear-gradient(174.6deg, #ff5043 83%, #161616 17%)',
    },
    "15%": {
      background: 'linear-gradient(174.9deg, #ff5043 83%, #161616 17%)',
    },
    "20%": {
      background: 'linear-gradient(175.2deg, #ff5043 83%, #161616 17%)',
    },
    "25%": {
      background: 'linear-gradient(175.5deg, #ff5043 83%, #161616 17%)',
    },
    "30%": {
      background: 'linear-gradient(175.8deg, #ff5043 83%, #161616 17%)',
    },
    "35%": {
      background: 'linear-gradient(176.1deg, #ff5043 83%, #161616 17%)',
    },
    "40%": {
      background: 'linear-gradient(176.4deg, #ff5043 83%, #161616 17%)',
    },
    "45%": {
      background: 'linear-gradient(176.7deg, #ff5043 83%, #161616 17%)',
    },
    "50%": {
      background: 'linear-gradient(177deg, #ff5043 83%, #161616 17%)',
    },
    "55%": {
      background: 'linear-gradient(177.3deg, #ff5043 83%, #161616 17%)',
    },
    "60%": {
      background: 'linear-gradient(177.6deg, #ff5043 83%, #161616 17%)',
    },
    "65%": {
      background: 'linear-gradient(177.9deg, #ff5043 83%, #161616 17%)',
    },
    "70%": {
      background: 'linear-gradient(178.2deg, #ff5043 83%, #161616 17%)',
    },
    "75%": {
      background: 'linear-gradient(178.5deg, #ff5043 83%, #161616 17%)',
    },
    "80%": {
      background: 'linear-gradient(178.8deg, #ff5043 83%, #161616 17%)',
    },
    "85%": {
      background: 'linear-gradient(179.1deg, #ff5043 83%, #161616 17%)',
    },
    "90%": {
      background: 'linear-gradient(179.4deg, #ff5043 83%, #161616 17%)',
    },
    "95%": {
      background: 'linear-gradient(179.7deg, #ff5043 83%, #161616 17%)',
    },
    "100%": {
      background: 'linear-gradient(180deg, #ff5043 83%, #161616 17%)',
    },

  },

  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  buttonContainer: {
    display: 'inline-flex',
    height: '50px',
    maxWidth: '250px',
    backgroundColor: '#000',
    borderRadius: '35px',
    flexDirection: 'row',
    //marginTop: '10px',
    marginLeft: '5rem',
    justifyContent: 'start',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginLeft: "90px",
      marginTop: "10px"
    }
  },
  desktopContainer: {
    display: "block",
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  mobileContainer: {
    display: "none",
    [theme.breakpoints.down('xs')]: {
      display: "block",
      // backgroundImage:`url(${web})`
    }
  },
  mobileInnerContainer: {
    //backgroundImage: `url(${web})`,
    //zIndex:100000,
    //height:"150px",
    backgroundBlendMode: "multiply",
    backgroundPosition: "center",
    backgroundSize: "350px",
    backgroundRepeat: "no-repeat",
    paddingTop: "2rem",
    paddingBottom: "4rem"
  },
  mobileText1: {
    fontFamily: 'Aeonik',
    fontWeight: 500,
    fontSize: '35px',
    color: '#ffffff',
    marginBottom: '15px',
    textAlign: 'center',
    lineHeight: '50px',
  },
  mobileText2: {
    fontFamily: 'Aeonik',
    fontWeight: 500,
    fontSize: '20px',
    color: '#ffffff',
    marginBottom: '15px',
    textAlign: 'center',
    lineHeight: '50px',
  },
  buttonText: {
    fontFamily: 'Aeonik',
    color: '#fff',
    paddingTop: '12px',
    paddingBottom: '10px',
    paddingLeft: '15px',
    fontSize: '1rem',
    textAlign: "center"
  },
  buttonInnerContainer: {
    height: '32px',
    width: '32px',
    borderRadius: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '6px',
    marginTop: '10px'
  },
  buttonContainer2: {

    display: 'flex',
    flexDirection: 'row',
    marginRight: '30px',
    marginTop: '20px',

    [theme.breakpoints.down('xs')]: {
      //display:"none",
      marginRight: "15px"
    }
  },
  head: {
    backgroundImage: `url(${pattern4})`,
    backgroundSize: "200px",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "top",
    zIndex: 20000000
  },
  mobileImage: {
    width: "100%"
  },
  innerMain: {
    overflow: "hidden",
    display: 'inline-flex',
    flexDirection: 'row',
    zIndex: 100,
    position: 'fixed',
    width: '100%',
    justifyContent: "space-between"
  },
  logo: {
    width: "150px",
    marginLeft: "3rem",
    marginTop: "2rem",
    [theme.breakpoints.down('xs')]: {
      marginLeft: "1rem"
    }
  },
  pattern: {
    position: 'absolute', left: '0px', marginTop: '11.5%', zIndex: "1", backgroundPositionX: "left", height: '500px', width: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  patternTop: {
    position: 'absolute', right: '0px', marginTop: '-30%', zIndex: "1", backgroundPositionX: "right", height: '500px', width: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));



function App() {
  const classes = useStyles();
  const [colorChange, setColorchange] = useState(false);
  const [colorChange2, setColorchange2] = useState('0');
  const [colorChange3, setColorchange3] = useState('0');
  const [colorChange4, setColorchange4] = useState('0');
  const [colorChange5, setColorchange5] = useState('0');
  const [h, seth] = useState(false);

  const changeNavbarColor = () => {
    if (window.onscrollY > 899) {
      seth(true);
    }
    if (window.scrollY >= 900 && window.scrollY < 2400) {
      setColorchange(false);
      setColorchange2('2');
      setColorchange3('0');
      setColorchange4('0');
      setColorchange5('0');
    }
    else if (window.scrollY >= 2400 && window.scrollY <= 3100) {
      setColorchange(true);
      setColorchange2('1');
      setColorchange3('2');
      setColorchange4('1');
      setColorchange5('1');
    }
    else if (window.scrollY > 3100 && window.scrollY <= 3800) {
      setColorchange(false);
      setColorchange2('0');
      setColorchange3('0');
      setColorchange4('2');
      setColorchange5('0');
    }
    //else if (window.scrollY > 4450 && window.scrollY <= 5780) {
    //setColorchange(false);
    //setColorchange2('0');
    //setColorchange3('0');
    //setColorchange4('2');
    //setColorchange5('0');
    //}
    else if (window.scrollY > 3800 && window.scrollY <= 4600) {
      setColorchange(true);
      setColorchange2('1');
      setColorchange3('1');
      setColorchange4('1');
      setColorchange5('2');
    }

    else {
      setColorchange(false);
      setColorchange2('0');
      setColorchange3('0');
      setColorchange4('0');
      setColorchange5('0');
    }
  };
  window.addEventListener('scroll', changeNavbarColor);




  return (
    <>

      <ThemeProvider theme={ttheme}>
        <div className={classes.innerMain}>
          {/* <Particles
            params={{
              particles: {
                number: {
                  value: 200,
                  density: {
                    enable: true,
                    value_area: 1000,
                  }
                },
              },
            }}
          /> */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }} className={classes.header1}>
            <Link to="info" smooth={true}><img src={(() => {
              switch (colorChange) {
                case false: return logo1;
                case true: return logo2;
                default: return logo1;
              }
            })()} className={classes.logo} /></Link>
            <Typography className={(() => {
              switch (colorChange2) {
                case '0': return classes.itemFirst;
                case '1': return classes.itemFirst1;
                case '2': return classes.itemFirst2;
                default: return classes.text2;
              }
            })()}><Link to="info" smooth={true}>Manifesto</Link></Typography>
            <Typography className={(() => {
              switch (colorChange3) {
                case '0': return [classes.item, classes.textcolor];
                case '1': return [classes.item, classes.textcolor1];
                case '2': return [classes.item, classes.textcolor2];
                default: return [classes.item, classes.textcolor];
              }
            })()}><Link to="founders" smooth={true}>Team</Link></Typography>
            <Typography className={(() => {
              switch (colorChange4) {
                case '0': return [classes.item, classes.textcolor];
                case '1': return [classes.item, classes.textcolor1];
                case '2': return [classes.item, classes.textcolor2];
                default: return [classes.item, classes.textcolor];
              }
            })()}><Link to="about" smooth={true}>News</Link></Typography>
            <Typography className={(() => {
              switch (colorChange5) {
                case '0': return [classes.item, classes.textcolor];
                case '1': return [classes.item, classes.textcolor1];
                case '2': return [classes.item, classes.textcolor2];
                default: return [classes.item, classes.textcolor];
              }
            })()}><Link to="people" smooth={true}>Jobs</Link></Typography>

          </div>
          <div className={classes.buttonContainer2}>
            <Link to="contact" smooth={true}><Button name="Contact Us" /></Link>
          </div>


        </div>
        <div id="butter">
          <div className={h ? classes.main1 : classes.main} id="home" style={{ overflow: "hidden", zIndex: '2', marginBottom: '-1%' }}>

            <div style={{ display: 'flex', justifyContent: 'center', height: '80vh', alignItems: 'center', flexDirection: 'column', paddingTop: '100px' }}>
              <img src={top} className={classes.patternTop} />
              <img src={bottomLeft} className={classes.pattern} />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', zIndex: "2" }}>
                <Grid container>
                  <Grid item xs={12} sm={6} >
                    <div className={classes.desktopContainer}>
                      <div data-aos="zoom-out-up">
                        <Typography className={classes.centerText}> Every Art, Idea, and innovation deserves an influential brand</Typography>
                        <Typography className={classes.centerTextSmall}>We work with empathy, for impact</Typography>
                      </div>
                      <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'row', marginTop: '50px' }}>
                        <div className={classes.buttonContainer}>
                          <Typography className={classes.buttonText}>Let’s get in touch</Typography>
                          <div className={classes.buttonInnerContainer}>
                            <WhatsAppIcon style={{ fill: 'whitesmoke', height: '32px', width: '32px', }} />
                          </div>
                        </div>
                      </Container>
                    </div>
                    <div className={classes.mobileContainer}>
                      <div className={classes.mobileInnerContainer}>
                        <img src={web} className={classes.mobileImage} />
                        <Typography className={classes.mobileText1}> Every Art, Idea, and innovation deserves an influential brand</Typography>
                        <Typography className={classes.mobileText2}>We work with empathy, for impact</Typography>
                        <div className={classes.buttonContainer}>
                          <Typography className={classes.buttonText}>Let’s get in touch</Typography>
                          <div className={classes.buttonInnerContainer}>
                            <WhatsAppIcon style={{ fill: 'whitesmoke', height: '32px', width: '32px', }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.desktopContainer}>
                    <div>
                      <img src={web} style={{ height: 'auto', width: '90%', marginLeft: '0px' }}></img>
                    </div>
                  </Grid>
                </Grid>



              </div>

            </div>

          </div>
          {/*<img src={pattern4} style={{position:"absolute", width:"500px", bottom:"-1%"}}/>*/}
          <Information />
          <Founders />
          <OurThoughts />
          <People />
          <StayUpdated />
        </div>

      </ThemeProvider>

    </>
  );
}

export default App;
